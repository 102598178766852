/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

/* Import Sass mixins, variables, Compass modules, etc. */
@import "base";

/* Layout rules */
// @import "partials/layouts/responsive";

/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 *
 * Custom for Garrison Dental
 *
 */

//
// Background pattern
//
html {
  background: white;
}

/**
 * Background
 */
body {
  font-size: 14px;
  line-height: 1.4em;
}

#main,
.region-bottom {
  background: white;
}

#main,
.region-bottom {
  background: white;
  z-index: 50;
  position: relative;
}

//
// Page shadow
//

// #header,
.region-bottom {
  // @include box-shadow-horizontal;

  padding-left: 0px;
  padding-right: 0px;

  .block {
    padding: 0;
    margin: 0;
  }
}

html.no-boxshadow #footer-wrapper {
  // background-image: url("../images/footer-shadow1x.png");
  // background-position: center top;
  // background-repeat: no-repeat;
}

#logo-wrpr {}

#site-logo,
#logo-wrpr {
  height: 56px;
  width: 167px;
}

#site-logo {
  display: block;
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background-color: transparent;
  background: url("../images/svg/logo.svg") no-repeat scroll top right;
  background-size: contain;
}

#logo-footer-wrpr,
#site-logo-footer {
  height: 34px;
  width: 100px;
}

#logo-footer-wrpr,
#g-address {
  float: right;
  clear: both;
}

#site-logo-footer {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: url("../images/svg/logo.svg") no-repeat scroll top left;
  background-size: contain;
}

html.no-svg {
  #site-logo {
    background: url("../images/logo1x.png") no-repeat scroll top right;
  }

  #site-logo-footer {
    background: url("../images/logo-footer1x.png") no-repeat scroll top left;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  html.no-svg {
    #site-logo {
      background: url("../images/svg/logo2x.png") no-repeat scroll top right;
      background-size: 163px 56px;
    }

    #site-logo-footer {
      background: url("../images/svg/logo-footer2x.png") no-repeat scroll top left;
      background-size: 100px 34px;
    }
  }

  html.lt-ie9 {
    #site-logo {
      background: url("../images/svg/logo1x.png") no-repeat scroll top right;
    }

    #site-logo-footer {
      background: url("../images/svg/logo-footer1x.png") no-repeat scroll top left;
    }
  }
} /* END RETINA MEDIA QUERY */


#main {
  // @include box-shadow-horizontal;
}

.sidebar {
  .block {
    margin-bottom: 18px;
  }
}

::selection {
  background: $link-color; /* Safari */
  color: lighten($link-color, 60%);
}

::-moz-selection {
  background: $link-color; /* Firefox */
  color: lighten($link-color, 60%);
}

// Places we don't want a hover state. Handled by color change
.region-navigation,
// .region-header,
.product-section-list,
.product-subsection-title {
  a {
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }
}

/* Menus
========================================================================== */
.block-simple-subscription-subscribe,
.block-menu-secondary-menu,
#navigation,
.block-menu-menu-products {
  h2 {
    display: none;
  }

  li {
    list-style: none;
  }
}

#block-garrisonblocks-country-indicator {
  float: right;

  img {
    width: 24px;
    height: 13px;
    margin-left: 5px;
  }

  img.active {
    border: 1px solid $color-orange;
  }

  img.inactive {
    border: 1px solid white;
  }
}

/* Main
========================================================================== */

body.front .page__title {
  @include element-invisible;
}

// Ensure wysiwyg fields do not collapse when elements inside are floated
.body {
  overflow: hidden;
}

#main-wrapper {
  overflow: hidden;
}

#main {
  padding-top: 0px;
}

//
// Flexslider
//
.flexslider {
  border: none;
  box-shadow: none;
}

#content-header {
  position: relative;
  margin-bottom: 20px;
}

body.front #content-header {
  margin-bottom: 0;
}

//
// Site logo
//
#block-garrisonblocks-site-logo.block {
  padding: 0px 0 6px 0;
}

//
// Main Content
//
.region-sidebar-first {
  padding: 0px;
}

#content {
  padding-right: 0px;
  padding-bottom: 15px;
}

body.front #content {
  padding-bottom: 0;

  a.feed-icon {
    display: none;
  }
}

#block-views-related-products-block, #slide-nav {
  .node-title {
    min-height: 34px;
  }

  .product-info {
    display: block;
    margin-top: 2px;
    margin-bottom: 2px;

    &.display-price, &.sell-price {
      // text-align: right;
      clear: both;
      // float: none;
      color: $primary-color;
    }
  }

  .add-to-cart {
    .attributes {
      label {
        position: absolute;
        margin-top: 5px;
      }
    }

    text-align: right;
  }

  .form-item-qty {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: baseline;

    label {
      display: none;
    }
  }

  .form-actions {
    display: inline-block;
    vertical-align: baseline;

    .form-submit {
      margin-right: 0;
    }
  }
}

.flexslider .slides img {
  // width: auto;
}

/* Page Bottom
========================================================================== */
#region-bottom-wrapper {
  overflow: hidden;
}

#block-views-homepagesubfeature-block-2 {
  .views-row {
    margin-bottom: 8px;
  }
}

#block-views-homepagesubfeature-block-1 {
  overflow: hidden;
  padding: 20px 25px;

  .views-row {
    float: left;
    margin-right: 13px;
  }

  .views-row-3 {
    margin-right: 0;
  }
}

// sidebar ID = 1, homepage ID = 2
#block-views-homepagesubfeature-block-2,
#block-views-homepagesubfeature-block-1 {
  .views-row {
    border: 1px solid #dddddd;
  }

  .views-field-title,
  .views-field-title-field {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #dddddd;

    @include gradient-title;

    a {
      display: block;
      text-decoration: none;
      color: $color-grey-dark;
      font-weight: 700;

      &:hover {
        color: $link-color-hover;
      }
    }
  }

  .views-field-field-sub-feature-image {
    line-height: 0;
  }

  .views-row-last {
    margin: 0;
  }
}